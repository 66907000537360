import { useState, useEffect, useCallback, useMemo } from "react";
import { listSummaryPCPS } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import awsconfig from '../../../aws-exports';
import tableColumns from './summary_columns';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const groupTypes = ['day', 'week', 'month', 'quarter', 'year'];
const Summary = ({ loading, onRefresh, data, fetchData }) => {
  const [isLoading, setLoading] = useState(loading); 
  const [groupByType, setGroupByType] = useState('week');
  
  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };


  useEffect(() => {
    console.log("Dados brutos:", data);
  }, [data]);


  function getWeekNumber(date) {
    const oneJan = new Date(date.getFullYear(), 0, 1);
    const numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));
    const result = Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
    return result;
  }
  const groupData = useMemo(() => {
    // Ordena os dados por 'reference_day' antes de agrupá-los
    const sortedData = [...data].sort((a, b) => new Date(b.reference_day) - new Date(a.reference_day));
  
    return groupBy(sortedData, (item) => {
      const reference_day = new Date(item.reference_day);
      switch (groupByType) {
        case 'week':
          const weekNumber = getWeekNumber(reference_day);
          return `(${reference_day.getFullYear()}) Week ${weekNumber.toString().padStart(2, '0')}`;
        case 'month':
          return `(${reference_day.getFullYear()}) Month ${(reference_day.getMonth() + 1)}`;
        case 'quarter':
          return `(${reference_day.getFullYear()}) Q${Math.ceil((reference_day.getMonth() + 1) / 3)}`;
        case 'year':
          return `(${reference_day.getFullYear()})`;
        default:
          return reference_day.toISOString().split('T')[0];
      }      
    });
  }, [data, groupByType]);

  const aggregatedData = useMemo(() => {
    const aggregated = [];
    for (const key in groupData) {
      if (groupData.hasOwnProperty(key)) {
        const items = groupData[key];
        const aggregatedItem = { reference_day: key };
        for (const item of items) {
          for (const column in item) {
            if (column !== 'reference_day' && (typeof item[column] === 'number' || typeof item[column] === 'string')) {
              aggregatedItem[column] = (aggregatedItem[column] || 0) + parseFloat(item[column]);
            }
          }
        }
        aggregated.push(aggregatedItem);
      }
    }
    return aggregated;
  }, [groupData]);

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);  

  const aggregatedDataSorted = useMemo(() => {
    // Copie os dados agregados para evitar mutações
    const aggregatedCopy = [...aggregatedData];
  
    // Ordena os dados pelo campo 'reference_day' em ordem decrescente
    aggregatedCopy.sort((a, b) => new Date(b.reference_day) - new Date(a.reference_day));
  
    return aggregatedCopy;
  }, [aggregatedData]);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );
  const handleRefresh = async () => {
    try {
      setLoading(true);
      await fetchData(); // Call fetchData function passed from props
      setLoading(false);
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };
  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Typography
            sx={{
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            Group By:
          </Typography>
          {groupTypes.map(type => (
            <Chip
              key={type}
              label={type}
              variant={groupByType === type ? 'filled' : 'outlined'}
              color={groupByType === type ? 'primary' : 'default'}
              onClick={() => handleGroupByChange(type)}
            />
            
          ))}
                  <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={onRefresh}
            sx={{ marginTop: '10px' }}
          >
            Refresh Data
          </Button>
        </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item lg={3} xs={3} md={3}>
        <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            PCP - Summary
          </Typography>
        </Grid>
      </Grid>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
      </Typography>
      {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
      ) : (
      <DataGrid
      
        sx={{
          marginTop: '2vh',
          marginLeft: 1,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '1px solid #e0e0e0',
          borderRadius: '10px',
          minHeight: '40vh',
          maxHeight: '90vh',
          overflow: 'auto', // Enable vertical scrolling if needed
        }}
        rows={aggregatedData}
        columns={[
          { field: 'reference_day', headerName: 'Reference Day', flex: 1, },
          { field: 'total_boxes_final', type: 'number' , headerName: 'Total Boxes', flex: 1, align: 'center', headerAlign: 'center', },
          { field: 'count_shipments_cancelled', type: 'number' , headerName: 'Cancelled (After 24h)', flex: 1, align: 'center', headerAlign: 'center', },
          { field: 'cancellation_ratio', headerName: 'Cancellation Ratio', flex: 1, align: 'center', headerAlign: 'center',
          valueGetter: params => {
            const rowData = params.row; // Get the data for the current row
            const fops = rowData['count_shipments_cancelled']; // Access the value of 'fops_shipment'
            const total = rowData['total_boxes_final']; // Access the value of 'total_shipments'
            const ratio = fops && total ? (fops / total) * 100 : 0;
            return ratio.toFixed(2) + '%'; // Convert to percentage and append '%' symbol
          }
        },
        { field: 'fdps_boxes', type: 'number' , headerName: 'FDPS (boxes)', flex: 1, align: 'center', headerAlign: 'center',},
        { field: 'fdps_ratio', headerName: 'FDPS Ratio', flex: 1, align: 'center', headerAlign: 'center',
        valueGetter: params => {
          const rowData = params.row; // Get the data for the current row
          const fops = rowData['fdps_boxes']; // Access the value of 'fops_shipment'
          const total = rowData['total_boxes_final']; // Access the value of 'total_shipments'
          const ratio = fops && total ? (fops / total) * 100 : 0;
          return ratio.toFixed(2) + '%'; // Convert to percentage and append '%' symbol
        }
        },
          { field: 'fops_boxes', type: 'number' , headerName: 'FOPS (boxes)', flex: 1, align: 'center', headerAlign: 'center',},
          { field: 'fops_ratio', headerName: 'FOPS Ratio', flex: 1, align: 'center', headerAlign: 'center',
            valueGetter: params => {
              const rowData = params.row; // Get the data for the current row
              const fops = rowData['fops_boxes']; // Access the value of 'fops_shipment'
              const total = rowData['total_boxes_final']; // Access the value of 'total_shipments'
              const ratio = fops && total ? (fops / total) * 100 : 0;
              return ratio.toFixed(2) + '%'; // Convert to percentage and append '%' symbol
            }
          },

          { field: 'sops_boxes', type: 'number' , headerName: 'SOPS (boxes)', flex: 1, align: 'center', headerAlign: 'center',},
          { field: 'sdps_boxes', type: 'number' , headerName: 'SDPS (boxes)', flex: 1, align: 'center', headerAlign: 'center',},
          { field: 'ead_boxes', type: 'number' , headerName: 'EAD (boxes)', flex: 1, align: 'center', headerAlign: 'center',},
        ]}
        components={{
          Toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        editMode="row"
        disableColumnSelector
        disableDensitySelector
        initialState={{
            pagination: { paginationModel: { pageSize: 50 } },
            sortModel: [{ field: 'reference_day', sort: 'desc' }],
        }}  
      />
      )} 
    </div>
    );
};

export default (Summary);