import { useState, useEffect, useCallback, useMemo } from "react";
import { listPCPPreWBRS } from "../../../graphql/queries";
import { updateMissesEasyShip } from "../../../graphql/mutations";
import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Chip, Grid, Snackbar } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import awsconfig from '../../../aws-exports';
import { tableColumnsAdmin, tableColumnsOps, tableColumnsView } from './PreWBR_PCP_columns';
import './PreWBR.css';
import { subWeeks  } from 'date-fns';
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const MissesDayES = ({ username, userGroup, }) => {
  const [editedRows, setEditedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(true); 
  const [data, setData] = useState([]);

  function getLastWeekNumber() {
    const now = new Date();
    const thisWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startOfYear = new Date(now.getFullYear(), 0, 1);
  
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillThisWeek = thisWeek - startOfYear;
    let weekNumberThisWeek = Math.ceil(millisecondsTillThisWeek / millisecondsPerWeek);
    let weekNumberLastWeek = Math.ceil(millisecondsTillThisWeek / millisecondsPerWeek) -1 ;
  
    // Check if the week number is negative
    if (weekNumberLastWeek <= 0) {
      weekNumberLastWeek =0;
    }
  
    return weekNumberLastWeek;
  }
  const lastWeekNumber = getLastWeekNumber();

  async function fetchData() {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      const currentYear = new Date().getFullYear(); 
      do {
        const response = await API.graphql(graphqlOperation(listPCPPreWBRS, {
          filter: {
            and: [

              {
                reference_day: {
                  beginsWith: `${currentYear}-`, 
                },
              },
            ],
          },
          limit: 200000,
          nextToken: nextToken 
          
        }));
  
        const fetchedItems = response.data.listPCPPreWBRS.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listPCPPreWBRS.nextToken;
      } while (nextToken);
      setData(items);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
}, []);


const handleRefreshData = async () => {
  try {
    setLoading(true);
    await fetchData();
    setLoading(false);
  } catch (error) {
    console.error('Error refreshing data:', error);
  }
};
  // Calculate the ratio for the session user only
  const sessionUserStats = data.reduce((stats, row) => {
    if (row.owner === username) {
      stats.total++;
      if (row.reason !== "LH_BY_CARRIER" && !row.reason) {
        stats.withoutReason++;
      }
    }
    return stats;
  }, { total: 0, withoutReason: 0 });

  sessionUserStats.ratio = sessionUserStats.withoutReason / sessionUserStats.total;
  const ratioPercentage = (sessionUserStats.ratio * 100).toFixed(0);
  let chipColor = 'error';
  let chipIcon = <ErrorIcon />;
  let chipLabel = `${ratioPercentage}% of your misses have no reason mapped`;

  if(ratioPercentage == 0){
    chipColor = 'success'
    chipIcon = <CheckCircleIcon />
    chipLabel = `No pending actions. Congrats!`
  }
  if (isNaN(ratioPercentage)) {
    chipColor = 'success';
    chipIcon = <CheckCircleIcon />;
    chipLabel = `No pending actions.`;
  }

  // Get the date for 1 week ago
  const sortedData = useMemo(() => {

    const sortedItems = data.sort((a, b) => {
      // Sort by date column in descending order
      const dateComparison = new Date(b.reference_day) - new Date(a.reference_day);
      if (dateComparison !== 0) {
        return dateComparison;
      }
  
      const ordersComparison = b.miss_boxes - a.miss_boxes;
      if (ordersComparison !== 0) {
        return ordersComparison;
      }
  
      const opsComparison = b.ops_support - a.ops_support;
      if (opsComparison !== 0) {
        return opsComparison;
      }
  
    });
  
    return sortedItems;
  }, [data]);

let tableColumns;

if (userGroup.includes('admin') )  {
  tableColumns = tableColumnsAdmin; 
} else if (userGroup.includes('ops_owner') || userGroup.includes('managers')){
  tableColumns = tableColumnsOps; 
} else {
  tableColumns = tableColumnsView; 
}

  const handleProcessRowUpdateError = (error, params) => {
    console.error('Error updating row:', error);
    // Perform additional error handling logic, such as showing an error message
  };

  const handleEditRow = useCallback(async (params) => {
    const { id, seller_name, week, ops_support, reference_day, total_boxes, miss_boxes, reason, sub_reason, miss_type, self_report_survey, classification, _version } = params;
    const editedRow = { 
      id,
      seller_name,
      week,
      ops_support,
      reference_day,
      miss_type,
      total_boxes,
      miss_boxes,
      reason,
      sub_reason,
      classification,
      self_report_survey,
      edited_by: username,
      _version };
    // Check if the edited row is already in the editedRows state
    const existingRow = editedRows.find((row) => row.id === editedRow.id);
    try {
      if (existingRow) {
        // If the edited row is already in the state, update its value
        const updatedRows = editedRows.map((row) =>
          row.id === editedRow.id ? editedRow : row
        );
        setEditedRows(updatedRows);
      } else {
        // If the edited row is not in the state, add it
        setEditedRows((prevRows) => [...prevRows, editedRow]);
      }
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  }, [editedRows, username]);
  
  const handleUpdateDatabase = async () => {
    try {
      for (const { id, seller_name, week, ops_support, reference_day, miss_type, total_boxes, miss_boxes, reason, sub_reason, self_report_survey, classification, _version } of editedRows) {
        const input = {
          id : id,
          week : week,
          seller_name : seller_name,
          ops_support : ops_support,
          reference_day : reference_day,
          miss_type : miss_type,
          total_boxes : total_boxes,
          miss_boxes : miss_boxes,
          reason : reason,
          sub_reason : sub_reason,
          classification : classification,
          self_report_survey : self_report_survey,
          _version: _version,
          edited_by: username, 
        };
        // Perform the GraphQL mutation for each edited row
        await API.graphql(graphqlOperation(updateMissesEasyShip, { input }));
      }
      setInitialData((prevData) => {
        const updatedData = prevData.map((row) => {
          const editedRow = editedRows.find((editedRow) => editedRow.id === row.id);
          return editedRow ? { ...row, ...editedRow } : row;
        });
        return updatedData;
      });
      // Clear the edited rows
      setEditedRows([]);
      setOpen(false);
      setOpenSnackbar(true); 
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  };  
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
      <GridToolbarExport />
      <Button
        variant="contained"
        color="primary"
        disabled={editedRows.length === 0} 
        onClick={handleUpdateDatabase}
      >
        Salvar
      </Button>
      <GridToolbarQuickFilter
        sx={{
          position: 'absolute',
          padding: '18px',
          right: '15px'
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
        <div>
          <Grid container spacing={2} direction="row">
          <Grid item xs={3} md={3}>
            <Typography
                sx={{
                    marginTop: '2vh',
                    marginLeft: 3,
                    fontFamily: 'Source Sans Pro',
                    fontSize: 'calc(15px + 0.5vh)',
                    fontWeight: 'bold',
                }}
                >
                PCP - Pre WBR
                </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
                <Button
                variant="contained"
                color="primary"
                onClick={handleRefreshData}
                >
                Refresh Data
              </Button>
          </Grid>
          <Grid item xs={8} md={8} className="blankReasonsChip" sx={{ marginTop: '3vh', textAlign: 'right' }}>
              <Chip icon={chipIcon} label={chipLabel} color={chipColor} />
          </Grid>
          </Grid>
          {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
            ) : (
            <DataGrid
                sx={{
                  marginTop: '2vh',
                  marginLeft: 2,
                  marginRight: 2,
                  padding: '10px 18px 18px 18px',
                  backgroundColor: "#FFFFFF",
                  border: '0px',
                  borderRadius: '10px',
                  maxHeight: '90vh',
                  overflow: 'auto', 
              }}
              rows={sortedData}
              columns={tableColumns}
              processRowUpdate={handleEditRow}
              onCellEditCommit={handleEditRow}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              components={{
                Toolbar: CustomToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              editMode="row"
              checkboxSelection
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
              }}
            />
            )}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000} 
              onClose={handleSnackbarClose}
              message="Edition saved successfully!"
              action={
                <Button color="primary" size="small" onClick={handleSnackbarClose}>
                  Close
                </Button>
              }
            />
        </div>
    </div>
  );
};


export default withAuthenticator(MissesDayES);