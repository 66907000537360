/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSellerDataPCP2 = /* GraphQL */ `
  mutation CreateSellerDataPCP2(
    $input: CreateSellerDataPCP2Input!
    $condition: ModelSellerDataPCP2ConditionInput
  ) {
    createSellerDataPCP2(input: $input, condition: $condition) {
      id
      seller_id
      rank
      seller_name
      on_time
      second_attempt
      no_show
      cancelled
      seller_miss
      seller_miss_perc
      metrics_impact_perc
      business_relevance
      classification
      ops_support
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSellerDataPCP2 = /* GraphQL */ `
  mutation UpdateSellerDataPCP2(
    $input: UpdateSellerDataPCP2Input!
    $condition: ModelSellerDataPCP2ConditionInput
  ) {
    updateSellerDataPCP2(input: $input, condition: $condition) {
      id
      seller_id
      rank
      seller_name
      on_time
      second_attempt
      no_show
      cancelled
      seller_miss
      seller_miss_perc
      metrics_impact_perc
      business_relevance
      classification
      ops_support
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSellerDataPCP2 = /* GraphQL */ `
  mutation DeleteSellerDataPCP2(
    $input: DeleteSellerDataPCP2Input!
    $condition: ModelSellerDataPCP2ConditionInput
  ) {
    deleteSellerDataPCP2(input: $input, condition: $condition) {
      id
      seller_id
      rank
      seller_name
      on_time
      second_attempt
      no_show
      cancelled
      seller_miss
      seller_miss_perc
      metrics_impact_perc
      business_relevance
      classification
      ops_support
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSummaryPCP = /* GraphQL */ `
  mutation CreateSummaryPCP(
    $input: CreateSummaryPCPInput!
    $condition: ModelSummaryPCPConditionInput
  ) {
    createSummaryPCP(input: $input, condition: $condition) {
      id
      legacy_merchant_id
      seller_name
      alias
      tax_regime
      date_type
      reference_day
      count_shipments_cancelled
      total_box_shortage
      fops_shipment
      fops_boxes
      fdps_shipment
      fdps_boxes
      sops_shipment
      sops_boxes
      sdps_shipment
      sdps_boxes
      total_boxes_final
      total_first_boxes_final
      total_issued_boxes_fops
      total_issued_boxes_sops
      total_issued_boxes_sdps
      ead_shipment
      ead_boxes
      total_shipments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSummaryPCP = /* GraphQL */ `
  mutation UpdateSummaryPCP(
    $input: UpdateSummaryPCPInput!
    $condition: ModelSummaryPCPConditionInput
  ) {
    updateSummaryPCP(input: $input, condition: $condition) {
      id
      legacy_merchant_id
      seller_name
      alias
      tax_regime
      date_type
      reference_day
      count_shipments_cancelled
      total_box_shortage
      fops_shipment
      fops_boxes
      fdps_shipment
      fdps_boxes
      sops_shipment
      sops_boxes
      sdps_shipment
      sdps_boxes
      total_boxes_final
      total_first_boxes_final
      total_issued_boxes_fops
      total_issued_boxes_sops
      total_issued_boxes_sdps
      ead_shipment
      ead_boxes
      total_shipments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSummaryPCP = /* GraphQL */ `
  mutation DeleteSummaryPCP(
    $input: DeleteSummaryPCPInput!
    $condition: ModelSummaryPCPConditionInput
  ) {
    deleteSummaryPCP(input: $input, condition: $condition) {
      id
      legacy_merchant_id
      seller_name
      alias
      tax_regime
      date_type
      reference_day
      count_shipments_cancelled
      total_box_shortage
      fops_shipment
      fops_boxes
      fdps_shipment
      fdps_boxes
      sops_shipment
      sops_boxes
      sdps_shipment
      sdps_boxes
      total_boxes_final
      total_first_boxes_final
      total_issued_boxes_fops
      total_issued_boxes_sops
      total_issued_boxes_sdps
      ead_shipment
      ead_boxes
      total_shipments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSellerDataFBA = /* GraphQL */ `
  mutation CreateSellerDataFBA(
    $input: CreateSellerDataFBAInput!
    $condition: ModelSellerDataFBAConditionInput
  ) {
    createSellerDataFBA(input: $input, condition: $condition) {
      id
      email
      phone_nft
      phone_comercial
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSellerDataFBA = /* GraphQL */ `
  mutation UpdateSellerDataFBA(
    $input: UpdateSellerDataFBAInput!
    $condition: ModelSellerDataFBAConditionInput
  ) {
    updateSellerDataFBA(input: $input, condition: $condition) {
      id
      email
      phone_nft
      phone_comercial
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSellerDataFBA = /* GraphQL */ `
  mutation DeleteSellerDataFBA(
    $input: DeleteSellerDataFBAInput!
    $condition: ModelSellerDataFBAConditionInput
  ) {
    deleteSellerDataFBA(input: $input, condition: $condition) {
      id
      email
      phone_nft
      phone_comercial
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSFCPTManagement = /* GraphQL */ `
  mutation CreateSFCPTManagement(
    $input: CreateSFCPTManagementInput!
    $condition: ModelSFCPTManagementConditionInput
  ) {
    createSFCPTManagement(input: $input, condition: $condition) {
      id
      node
      shipment_id_missing_invoice
      order_id_missing_invoice
      shipment_id_invoice_ok
      exsd_timestamp
      last_updated
      cpt_date
      cpt_hour
      pending_shipments
      manifested_shipments
      shipments_today
      packed_shipments
      picked_shipments
      new_shipments
      pending_invoice
      SellerDataSF {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      seller_name
      ops_owner
      backup_owner
      phone1
      phone2
      nam
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sFCPTManagementSellerDataSFId
      __typename
    }
  }
`;
export const updateSFCPTManagement = /* GraphQL */ `
  mutation UpdateSFCPTManagement(
    $input: UpdateSFCPTManagementInput!
    $condition: ModelSFCPTManagementConditionInput
  ) {
    updateSFCPTManagement(input: $input, condition: $condition) {
      id
      node
      shipment_id_missing_invoice
      order_id_missing_invoice
      shipment_id_invoice_ok
      exsd_timestamp
      last_updated
      cpt_date
      cpt_hour
      pending_shipments
      manifested_shipments
      shipments_today
      packed_shipments
      picked_shipments
      new_shipments
      pending_invoice
      SellerDataSF {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      seller_name
      ops_owner
      backup_owner
      phone1
      phone2
      nam
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sFCPTManagementSellerDataSFId
      __typename
    }
  }
`;
export const deleteSFCPTManagement = /* GraphQL */ `
  mutation DeleteSFCPTManagement(
    $input: DeleteSFCPTManagementInput!
    $condition: ModelSFCPTManagementConditionInput
  ) {
    deleteSFCPTManagement(input: $input, condition: $condition) {
      id
      node
      shipment_id_missing_invoice
      order_id_missing_invoice
      shipment_id_invoice_ok
      exsd_timestamp
      last_updated
      cpt_date
      cpt_hour
      pending_shipments
      manifested_shipments
      shipments_today
      packed_shipments
      picked_shipments
      new_shipments
      pending_invoice
      SellerDataSF {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      seller_name
      ops_owner
      backup_owner
      phone1
      phone2
      nam
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sFCPTManagementSellerDataSFId
      __typename
    }
  }
`;
export const createSummaryVF = /* GraphQL */ `
  mutation CreateSummaryVF(
    $input: CreateSummaryVFInput!
    $condition: ModelSummaryVFConditionInput
  ) {
    createSummaryVF(input: $input, condition: $condition) {
      id
      shipped_units
      gas_tank
      ship_day
      shipped_orders
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSummaryVF = /* GraphQL */ `
  mutation UpdateSummaryVF(
    $input: UpdateSummaryVFInput!
    $condition: ModelSummaryVFConditionInput
  ) {
    updateSummaryVF(input: $input, condition: $condition) {
      id
      shipped_units
      gas_tank
      ship_day
      shipped_orders
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSummaryVF = /* GraphQL */ `
  mutation DeleteSummaryVF(
    $input: DeleteSummaryVFInput!
    $condition: ModelSummaryVFConditionInput
  ) {
    deleteSummaryVF(input: $input, condition: $condition) {
      id
      shipped_units
      gas_tank
      ship_day
      shipped_orders
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSummaryES = /* GraphQL */ `
  mutation CreateSummaryES(
    $input: CreateSummaryESInput!
    $condition: ModelSummaryESConditionInput
  ) {
    createSummaryES(input: $input, condition: $condition) {
      id
      owner
      week
      date
      classification
      mexsd_re_f
      cr
      late_handover
      orders
      lsr_re_f
      general_lsr
      dropoff_issue_re
      pickup_issue_re
      evaluation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSummaryES = /* GraphQL */ `
  mutation UpdateSummaryES(
    $input: UpdateSummaryESInput!
    $condition: ModelSummaryESConditionInput
  ) {
    updateSummaryES(input: $input, condition: $condition) {
      id
      owner
      week
      date
      classification
      mexsd_re_f
      cr
      late_handover
      orders
      lsr_re_f
      general_lsr
      dropoff_issue_re
      pickup_issue_re
      evaluation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSummaryES = /* GraphQL */ `
  mutation DeleteSummaryES(
    $input: DeleteSummaryESInput!
    $condition: ModelSummaryESConditionInput
  ) {
    deleteSummaryES(input: $input, condition: $condition) {
      id
      owner
      week
      date
      classification
      mexsd_re_f
      cr
      late_handover
      orders
      lsr_re_f
      general_lsr
      dropoff_issue_re
      pickup_issue_re
      evaluation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSellerDataES = /* GraphQL */ `
  mutation CreateSellerDataES(
    $input: CreateSellerDataESInput!
    $condition: ModelSellerDataESConditionInput
  ) {
    createSellerDataES(input: $input, condition: $condition) {
      id
      seller_name
      launch_date
      email
      phone
      owner
      backup_owner
      old_classification
      new_classification
      sales_cohort
      nam
      integrador
      ship_method
      mcid
      user_edition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSellerDataES = /* GraphQL */ `
  mutation UpdateSellerDataES(
    $input: UpdateSellerDataESInput!
    $condition: ModelSellerDataESConditionInput
  ) {
    updateSellerDataES(input: $input, condition: $condition) {
      id
      seller_name
      launch_date
      email
      phone
      owner
      backup_owner
      old_classification
      new_classification
      sales_cohort
      nam
      integrador
      ship_method
      mcid
      user_edition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSellerDataES = /* GraphQL */ `
  mutation DeleteSellerDataES(
    $input: DeleteSellerDataESInput!
    $condition: ModelSellerDataESConditionInput
  ) {
    deleteSellerDataES(input: $input, condition: $condition) {
      id
      seller_name
      launch_date
      email
      phone
      owner
      backup_owner
      old_classification
      new_classification
      sales_cohort
      nam
      integrador
      ship_method
      mcid
      user_edition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMissesEasyShip = /* GraphQL */ `
  mutation CreateMissesEasyShip(
    $input: CreateMissesEasyShipInput!
    $condition: ModelMissesEasyShipConditionInput
  ) {
    createMissesEasyShip(input: $input, condition: $condition) {
      id
      week
      owner
      seller_name
      date
      mid
      miss_type
      orders
      reason
      sub_reason
      comments
      pod
      poa
      remove_from_pm
      self_report
      ticket
      classification
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMissesEasyShip = /* GraphQL */ `
  mutation UpdateMissesEasyShip(
    $input: UpdateMissesEasyShipInput!
    $condition: ModelMissesEasyShipConditionInput
  ) {
    updateMissesEasyShip(input: $input, condition: $condition) {
      id
      week
      owner
      seller_name
      date
      mid
      miss_type
      orders
      reason
      sub_reason
      comments
      pod
      poa
      remove_from_pm
      self_report
      ticket
      classification
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMissesEasyShip = /* GraphQL */ `
  mutation DeleteMissesEasyShip(
    $input: DeleteMissesEasyShipInput!
    $condition: ModelMissesEasyShipConditionInput
  ) {
    deleteMissesEasyShip(input: $input, condition: $condition) {
      id
      week
      owner
      seller_name
      date
      mid
      miss_type
      orders
      reason
      sub_reason
      comments
      pod
      poa
      remove_from_pm
      self_report
      ticket
      classification
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMQBandQBR = /* GraphQL */ `
  mutation CreateMQBandQBR(
    $input: CreateMQBandQBRInput!
    $condition: ModelMQBandQBRConditionInput
  ) {
    createMQBandQBR(input: $input, condition: $condition) {
      id
      su_q2
      su_q3
      su_q4
      active_nodes
      su_quarter
      lsr
      scr
      su_month
      su_week
      week
      wow
      su_q1
      fdps
      lsr_perc
      scr_perc
      fdps_perc
      lsr_wow
      scr_wow
      fdps_wow
      ft_share
      scr_controllable_wow
      scr_controllable_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMQBandQBR = /* GraphQL */ `
  mutation UpdateMQBandQBR(
    $input: UpdateMQBandQBRInput!
    $condition: ModelMQBandQBRConditionInput
  ) {
    updateMQBandQBR(input: $input, condition: $condition) {
      id
      su_q2
      su_q3
      su_q4
      active_nodes
      su_quarter
      lsr
      scr
      su_month
      su_week
      week
      wow
      su_q1
      fdps
      lsr_perc
      scr_perc
      fdps_perc
      lsr_wow
      scr_wow
      fdps_wow
      ft_share
      scr_controllable_wow
      scr_controllable_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMQBandQBR = /* GraphQL */ `
  mutation DeleteMQBandQBR(
    $input: DeleteMQBandQBRInput!
    $condition: ModelMQBandQBRConditionInput
  ) {
    deleteMQBandQBR(input: $input, condition: $condition) {
      id
      su_q2
      su_q3
      su_q4
      active_nodes
      su_quarter
      lsr
      scr
      su_month
      su_week
      week
      wow
      su_q1
      fdps
      lsr_perc
      scr_perc
      fdps_perc
      lsr_wow
      scr_wow
      fdps_wow
      ft_share
      scr_controllable_wow
      scr_controllable_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMisses = /* GraphQL */ `
  mutation CreateMisses(
    $input: CreateMissesInput!
    $condition: ModelMissesConditionInput
  ) {
    createMisses(input: $input, condition: $condition) {
      id
      week
      ops_owner
      seller_name
      date
      node
      miss_type
      units
      reason
      subreason
      remove_from_pm
      comments
      self_report
      pod
      poa
      ticket
      classification
      carrier
      tracking_id
      recurrence
      su_week
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMisses = /* GraphQL */ `
  mutation UpdateMisses(
    $input: UpdateMissesInput!
    $condition: ModelMissesConditionInput
  ) {
    updateMisses(input: $input, condition: $condition) {
      id
      week
      ops_owner
      seller_name
      date
      node
      miss_type
      units
      reason
      subreason
      remove_from_pm
      comments
      self_report
      pod
      poa
      ticket
      classification
      carrier
      tracking_id
      recurrence
      su_week
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMisses = /* GraphQL */ `
  mutation DeleteMisses(
    $input: DeleteMissesInput!
    $condition: ModelMissesConditionInput
  ) {
    deleteMisses(input: $input, condition: $condition) {
      id
      week
      ops_owner
      seller_name
      date
      node
      miss_type
      units
      reason
      subreason
      remove_from_pm
      comments
      self_report
      pod
      poa
      ticket
      classification
      carrier
      tracking_id
      recurrence
      su_week
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSellerDataSF = /* GraphQL */ `
  mutation CreateSellerDataSF(
    $input: CreateSellerDataSFInput!
    $condition: ModelSellerDataSFConditionInput
  ) {
    createSellerDataSF(input: $input, condition: $condition) {
      node
      seller_name
      backup_owner
      capacity
      certificate
      classification
      efpm_delay
      efpm_ft_delay
      email1
      email2
      email3
      erp
      ft_percentage
      integrator
      is_node_active_fos
      is_temporary
      latest_cpt
      launch_date
      marketplace
      merchant_id
      nam
      ops_owner
      phone1
      phone2
      phone3
      return_rate_target
      seller_poc
      sprint
      capacity_update_request
      location_city
      location_address
      location_state
      is_active
      user_edition
      relaunch_seller
      wpp_group
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSellerDataSF = /* GraphQL */ `
  mutation UpdateSellerDataSF(
    $input: UpdateSellerDataSFInput!
    $condition: ModelSellerDataSFConditionInput
  ) {
    updateSellerDataSF(input: $input, condition: $condition) {
      node
      seller_name
      backup_owner
      capacity
      certificate
      classification
      efpm_delay
      efpm_ft_delay
      email1
      email2
      email3
      erp
      ft_percentage
      integrator
      is_node_active_fos
      is_temporary
      latest_cpt
      launch_date
      marketplace
      merchant_id
      nam
      ops_owner
      phone1
      phone2
      phone3
      return_rate_target
      seller_poc
      sprint
      capacity_update_request
      location_city
      location_address
      location_state
      is_active
      user_edition
      relaunch_seller
      wpp_group
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSellerDataSF = /* GraphQL */ `
  mutation DeleteSellerDataSF(
    $input: DeleteSellerDataSFInput!
    $condition: ModelSellerDataSFConditionInput
  ) {
    deleteSellerDataSF(input: $input, condition: $condition) {
      node
      seller_name
      backup_owner
      capacity
      certificate
      classification
      efpm_delay
      efpm_ft_delay
      email1
      email2
      email3
      erp
      ft_percentage
      integrator
      is_node_active_fos
      is_temporary
      latest_cpt
      launch_date
      marketplace
      merchant_id
      nam
      ops_owner
      phone1
      phone2
      phone3
      return_rate_target
      seller_poc
      sprint
      capacity_update_request
      location_city
      location_address
      location_state
      is_active
      user_edition
      relaunch_seller
      wpp_group
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
