/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSellerDataPCP2 = /* GraphQL */ `
  query GetSellerDataPCP2($id: ID!) {
    getSellerDataPCP2(id: $id) {
      id
      seller_id
      rank
      seller_name
      on_time
      second_attempt
      no_show
      cancelled
      seller_miss
      seller_miss_perc
      metrics_impact_perc
      business_relevance
      classification
      ops_support
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSellerDataPCP2s = /* GraphQL */ `
  query ListSellerDataPCP2s(
    $filter: ModelSellerDataPCP2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellerDataPCP2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        seller_id
        rank
        seller_name
        on_time
        second_attempt
        no_show
        cancelled
        seller_miss
        seller_miss_perc
        metrics_impact_perc
        business_relevance
        classification
        ops_support
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

export const listPCPPreWBRS = /* GraphQL */ `
  query listPCPPreWBRS(
    $filter: ModelPCPPreWBRFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPCPPreWBRS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        week
        seller_name
        ops_support
        reference_day
        miss_type
        total_boxes
        miss_boxes
        reason
        sub_reason
        edited_by
        classification
        self_report_survey
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;


export const syncSellerDataPCP2s = /* GraphQL */ `
  query SyncSellerDataPCP2s(
    $filter: ModelSellerDataPCP2FilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSellerDataPCP2s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        seller_id
        rank
        seller_name
        on_time
        second_attempt
        no_show
        cancelled
        seller_miss
        seller_miss_perc
        metrics_impact_perc
        business_relevance
        classification
        ops_support
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSummaryPCP = /* GraphQL */ `
  query GetSummaryPCP($id: ID!) {
    getSummaryPCP(id: $id) {
      id
      legacy_merchant_id
      seller_name
      alias
      tax_regime
      date_type
      reference_day
      count_shipments_cancelled
      total_box_shortage
      fops_shipment
      fops_boxes
      fdps_shipment
      fdps_boxes
      sops_shipment
      sops_boxes
      sdps_shipment
      sdps_boxes
      total_boxes_final
      total_first_boxes_final
      total_issued_boxes_fops
      total_issued_boxes_sops
      total_issued_boxes_sdps
      ead_shipment
      ead_boxes
      total_shipments
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSummaryPCPS = /* GraphQL */ `
  query ListSummaryPCPS(
    $filter: ModelSummaryPCPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSummaryPCPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        legacy_merchant_id
        seller_name
        alias
        tax_regime
        date_type
        reference_day
        count_shipments_cancelled
        total_box_shortage
        fops_shipment
        fops_boxes
        fdps_shipment
        fdps_boxes
        sops_shipment
        sops_boxes
        sdps_shipment
        sdps_boxes
        total_boxes_final
        total_first_boxes_final
        total_issued_boxes_fops
        total_issued_boxes_sops
        total_issued_boxes_sdps
        ead_shipment
        ead_boxes
        total_shipments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSummaryPCPS = /* GraphQL */ `
  query SyncSummaryPCPS(
    $filter: ModelSummaryPCPFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSummaryPCPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        legacy_merchant_id
        seller_name
        alias
        tax_regime
        date_type
        reference_day
        count_shipments_cancelled
        total_box_shortage
        fops_shipment
        fops_boxes
        fdps_shipment
        fdps_boxes
        sops_shipment
        sops_boxes
        sdps_shipment
        sdps_boxes
        total_boxes_final
        total_first_boxes_final
        total_issued_boxes_fops
        total_issued_boxes_sops
        total_issued_boxes_sdps
        ead_shipment
        ead_boxes
        total_shipments
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSellerDataFBA = /* GraphQL */ `
  query GetSellerDataFBA($id: ID!) {
    getSellerDataFBA(id: $id) {
      id
      email
      phone_nft
      phone_comercial
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSellerDataFBAS = /* GraphQL */ `
  query ListSellerDataFBAS(
    $filter: ModelSellerDataFBAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellerDataFBAS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone_nft
        phone_comercial
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSellerDataFBAS = /* GraphQL */ `
  query SyncSellerDataFBAS(
    $filter: ModelSellerDataFBAFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSellerDataFBAS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        phone_nft
        phone_comercial
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSFCPTManagement = /* GraphQL */ `
  query GetSFCPTManagement($id: ID!) {
    getSFCPTManagement(id: $id) {
      id
      node
      shipment_id_missing_invoice
      order_id_missing_invoice
      shipment_id_invoice_ok
      exsd_timestamp
      last_updated
      cpt_date
      cpt_hour
      pending_shipments
      manifested_shipments
      shipments_today
      packed_shipments
      picked_shipments
      new_shipments
      pending_invoice
      SellerDataSF {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      seller_name
      ops_owner
      backup_owner
      phone1
      phone2
      nam
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      sFCPTManagementSellerDataSFId
      __typename
    }
  }
`;
export const listSFCPTManagements = /* GraphQL */ `
  query ListSFCPTManagements(
    $filter: ModelSFCPTManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSFCPTManagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        node
        shipment_id_missing_invoice
        order_id_missing_invoice
        shipment_id_invoice_ok
        exsd_timestamp
        last_updated
        cpt_date
        cpt_hour
        pending_shipments
        manifested_shipments
        shipments_today
        packed_shipments
        picked_shipments
        new_shipments
        pending_invoice
        seller_name
        ops_owner
        backup_owner
        phone1
        phone2
        nam
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sFCPTManagementSellerDataSFId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSFCPTManagements = /* GraphQL */ `
  query SyncSFCPTManagements(
    $filter: ModelSFCPTManagementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSFCPTManagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        node
        shipment_id_missing_invoice
        order_id_missing_invoice
        shipment_id_invoice_ok
        exsd_timestamp
        last_updated
        cpt_date
        cpt_hour
        pending_shipments
        manifested_shipments
        shipments_today
        packed_shipments
        picked_shipments
        new_shipments
        pending_invoice
        seller_name
        ops_owner
        backup_owner
        phone1
        phone2
        nam
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        sFCPTManagementSellerDataSFId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSummaryVF = /* GraphQL */ `
  query GetSummaryVF($id: ID!) {
    getSummaryVF(id: $id) {
      id
      shipped_units
      gas_tank
      ship_day
      shipped_orders
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSummaryVFS = /* GraphQL */ `
  query ListSummaryVFS(
    $filter: ModelSummaryVFFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSummaryVFS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shipped_units
        gas_tank
        ship_day
        shipped_orders
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSummaryVFS = /* GraphQL */ `
  query SyncSummaryVFS(
    $filter: ModelSummaryVFFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSummaryVFS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shipped_units
        gas_tank
        ship_day
        shipped_orders
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSummaryES = /* GraphQL */ `
  query GetSummaryES($id: ID!) {
    getSummaryES(id: $id) {
      id
      owner
      week
      date
      classification
      mexsd_re_f
      cr
      late_handover
      orders
      lsr_re_f
      general_lsr
      dropoff_issue_re
      pickup_issue_re
      evaluation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSummaryES = /* GraphQL */ `
  query ListSummaryES(
    $filter: ModelSummaryESFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSummaryES(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        week
        date
        classification
        mexsd_re_f
        cr
        late_handover
        orders
        lsr_re_f
        general_lsr
        dropoff_issue_re
        pickup_issue_re
        evaluation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSummaryES = /* GraphQL */ `
  query SyncSummaryES(
    $filter: ModelSummaryESFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSummaryES(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        week
        date
        classification
        mexsd_re_f
        cr
        late_handover
        orders
        lsr_re_f
        general_lsr
        dropoff_issue_re
        pickup_issue_re
        evaluation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSellerDataES = /* GraphQL */ `
  query GetSellerDataES($id: ID!) {
    getSellerDataES(id: $id) {
      id
      seller_name
      launch_date
      email
      phone
      owner
      backup_owner
      old_classification
      new_classification
      sales_cohort
      nam
      integrador
      ship_method
      mcid
      user_edition
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSellerDataES = /* GraphQL */ `
  query ListSellerDataES(
    $filter: ModelSellerDataESFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellerDataES(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        seller_name
        launch_date
        email
        phone
        owner
        backup_owner
        old_classification
        new_classification
        sales_cohort
        nam
        integrador
        ship_method
        mcid
        user_edition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSellerDataES = /* GraphQL */ `
  query SyncSellerDataES(
    $filter: ModelSellerDataESFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSellerDataES(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        seller_name
        launch_date
        email
        phone
        owner
        backup_owner
        old_classification
        new_classification
        sales_cohort
        nam
        integrador
        ship_method
        mcid
        user_edition
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMissesEasyShip = /* GraphQL */ `
  query GetMissesEasyShip($id: ID!) {
    getMissesEasyShip(id: $id) {
      id
      week
      owner
      seller_name
      date
      mid
      miss_type
      orders
      reason
      sub_reason
      comments
      pod
      poa
      remove_from_pm
      self_report
      ticket
      classification
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMissesEasyShips = /* GraphQL */ `
  query ListMissesEasyShips(
    $filter: ModelMissesEasyShipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissesEasyShips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        week
        owner
        seller_name
        date
        mid
        miss_type
        orders
        reason
        sub_reason
        comments
        pod
        poa
        remove_from_pm
        self_report
        ticket
        classification
        user_edition
        order_id_miss
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMissesEasyShips = /* GraphQL */ `
  query SyncMissesEasyShips(
    $filter: ModelMissesEasyShipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMissesEasyShips(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        week
        owner
        seller_name
        date
        mid
        miss_type
        orders
        reason
        sub_reason
        comments
        pod
        poa
        remove_from_pm
        self_report
        ticket
        classification
        user_edition
        order_id_miss
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMQBandQBR = /* GraphQL */ `
  query GetMQBandQBR($id: ID!) {
    getMQBandQBR(id: $id) {
      id
      su_q2
      su_q3
      su_q4
      active_nodes
      su_quarter
      lsr
      scr
      su_month
      su_week
      week
      wow
      su_q1
      fdps
      lsr_perc
      scr_perc
      fdps_perc
      lsr_wow
      scr_wow
      fdps_wow
      ft_share
      scr_controllable_wow
      scr_controllable_perc
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMQBandQBRS = /* GraphQL */ `
  query ListMQBandQBRS(
    $filter: ModelMQBandQBRFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMQBandQBRS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        su_q2
        su_q3
        su_q4
        active_nodes
        su_quarter
        lsr
        scr
        su_month
        su_week
        week
        wow
        su_q1
        fdps
        lsr_perc
        scr_perc
        fdps_perc
        lsr_wow
        scr_wow
        fdps_wow
        ft_share
        scr_controllable_wow
        scr_controllable_perc
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMQBandQBRS = /* GraphQL */ `
  query SyncMQBandQBRS(
    $filter: ModelMQBandQBRFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMQBandQBRS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        su_q2
        su_q3
        su_q4
        active_nodes
        su_quarter
        lsr
        scr
        su_month
        su_week
        week
        wow
        su_q1
        fdps
        lsr_perc
        scr_perc
        fdps_perc
        lsr_wow
        scr_wow
        fdps_wow
        ft_share
        scr_controllable_wow
        scr_controllable_perc
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMisses = /* GraphQL */ `
  query GetMisses($id: ID!) {
    getMisses(id: $id) {
      id
      week
      ops_owner
      seller_name
      date
      node
      miss_type
      units
      reason
      subreason
      remove_from_pm
      comments
      self_report
      pod
      poa
      ticket
      classification
      carrier
      tracking_id
      recurrence
      su_week
      user_edition
      order_id_miss
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMisses = /* GraphQL */ `
  query ListMisses(
    $filter: ModelMissesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMisses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        week
        ops_owner
        seller_name
        date
        node
        miss_type
        units
        reason
        subreason
        remove_from_pm
        comments
        self_report
        pod
        poa
        ticket
        classification
        carrier
        tracking_id
        recurrence
        su_week
        user_edition
        order_id_miss
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMisses = /* GraphQL */ `
  query SyncMisses(
    $filter: ModelMissesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMisses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        week
        ops_owner
        seller_name
        date
        node
        miss_type
        units
        reason
        subreason
        remove_from_pm
        comments
        self_report
        pod
        poa
        ticket
        classification
        carrier
        tracking_id
        recurrence
        su_week
        user_edition
        order_id_miss
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSellerDataSF = /* GraphQL */ `
  query GetSellerDataSF($id: ID!) {
    getSellerDataSF(id: $id) {
      node
      seller_name
      backup_owner
      capacity
      certificate
      classification
      efpm_delay
      efpm_ft_delay
      email1
      email2
      email3
      erp
      ft_percentage
      integrator
      is_node_active_fos
      is_temporary
      latest_cpt
      launch_date
      marketplace
      merchant_id
      nam
      ops_owner
      phone1
      phone2
      phone3
      return_rate_target
      seller_poc
      sprint
      capacity_update_request
      location_city
      location_address
      location_state
      is_active
      user_edition
      relaunch_seller
      wpp_group
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSellerDataSFS = /* GraphQL */ `
  query ListSellerDataSFS(
    $filter: ModelSellerDataSFFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSellerDataSFS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSellerDataSFS = /* GraphQL */ `
  query SyncSellerDataSFS(
    $filter: ModelSellerDataSFFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSellerDataSFS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        node
        seller_name
        backup_owner
        capacity
        certificate
        classification
        efpm_delay
        efpm_ft_delay
        email1
        email2
        email3
        erp
        ft_percentage
        integrator
        is_node_active_fos
        is_temporary
        latest_cpt
        launch_date
        marketplace
        merchant_id
        nam
        ops_owner
        phone1
        phone2
        phone3
        return_rate_target
        seller_poc
        sprint
        capacity_update_request
        location_city
        location_address
        location_state
        is_active
        user_edition
        relaunch_seller
        wpp_group
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
