import PropTypes from 'prop-types';
import {Select, InputLabel, MenuItem, FormControl, Avatar } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

  const tableColumns = [
    {
      field: "seller_id",
      headerName: "Seller ID",
      headerClassName: 'customHeader',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      },
      {
        field: "seller_name",
        headerName: "Seller Name",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        align: 'center',

      },
      {
        field: "rank",
        headerName: "rank",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        align: 'center',

      },
      {
        field: "ops_support",
        headerName: "Ops Support",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
                <span style={{ marginLeft: '8px', fontFamily: 'Source Sans Pro', fontSize: 'calc(7px + 0.5vh)', }}>{opsOwner}</span>
              </div>
            );
          }
          return null; 
        },
      },
      {
        field: "on_time",
        headerName: "On Time",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        editable: false,
        align: 'center',
      },
      {
        field: "second_attempt",
        headerName: "2nd Attempt",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        editable: false,
        align: 'center',
      },
      {
        field: "no_show",
        headerName: "No Show",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        editable: false,
        align: 'center',
      },
      {
        field: "cancelled",
        headerName: "Cancelled",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        editable: false,
        align: 'center',
      },
      {
        field: "seller_miss",
        headerName: "Seller Miss",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        editable: false,
        align: 'center',
      },
      {
        field: "seller_miss_perc",
        headerName: "Seller Miss (%)",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        editable: false,
        align: 'center',
        valueFormatter: (params) => {
          const value = params.value * 100; 
          const formattedValue = `${value.toFixed(2)}%`;
    
          if (value > 0) {
            return `${formattedValue}`; 
          }
    
          return formattedValue;
        },
      },
      {
        field: "metrics_impact_perc",
        headerName: "Metrics Impact (%)",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        editable: false,
        align: 'center',
        valueFormatter: (params) => {
          const value = params.value * 100; 
          const formattedValue = `${value.toFixed(2)}%`;
    
          if (value > 0) {
            return `${formattedValue}`; 
          }
    
          return formattedValue;
        },
      },
      {
        field: "business_relevance",
        headerName: "Business Relevance",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        editable: false,
        align: 'center',
        valueFormatter: (params) => {
          const value = params.value * 100; 
          const formattedValue = `${value.toFixed(2)}%`;
    
          if (value > 0) {
            return `${formattedValue}`; 
          }
    
          return formattedValue;
        },
      },
      {
        field: "classification",
        headerName: "Classification",
        headerClassName: 'customHeader',
        flex: 1,
        headerAlign: 'center',
        editable: false,
        align: 'center',
      },
    ];


export default tableColumns;