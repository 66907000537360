import { useState, useEffect, useCallback, useMemo } from "react";
import { listSellerDataPCP2s } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation  } from 'aws-amplify';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Button, Snackbar, Alert, Chip, Grid  } from '@mui/material';
import awsconfig from '../../../aws-exports';
import { withAuthenticator,Loader, Flex, TabItem } from '@aws-amplify/ui-react';
import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import tableColumns from './seller_data_columns';
import './table-styles.css';

Amplify.configure(awsconfig);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const SellerDataPCP = ({ username, userGroup }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);


  
  // -------------- PRE WBR DATA --------------

  async function fetchData() {
    try {
      setLoading(true);
      let nextToken = null;
      const items = [];
      const currentYear = new Date().getFullYear(); 
      do {
        const response = await API.graphql(graphqlOperation(listSellerDataPCP2s, {
          limit: 200000,
          nextToken: nextToken 
          
        }));
  
        const fetchedItems = response.data.listSellerDataPCP2s.items;
        items.push(...fetchedItems);
  
        nextToken = response.data.listSellerDataPCP2s.nextToken;
      } while (nextToken);
      setData(items);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

    useEffect(() => {
        try {
          fetchData();
        } catch (error) {
          console.error('Error fetching user group information:', error);
        }
    }, []);

    const handleRefreshData = async () => {
      try {
        setLoading(true);
        await fetchData();
        setLoading(false);
      } catch (error) {
        console.error('Error refreshing data:', error);
      }
    };
    const CustomToolbar = () => (
      <GridToolbarContainer
        sx={{
          marginBottom: '10px',
        }}
        spacing={2}
      >
        <GridToolbarExport />
        <GridToolbarQuickFilter
          sx={{
            position: 'absolute',
            padding: '18px',
            right: '15px'
          }}
        />
      </GridToolbarContainer>
    );
    
  
return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
        <Grid container spacing={2} direction="row">
        <Grid item lg={3} xs={3} md={3}>
        <Typography
            sx={{
                marginTop: '2vh',
                marginLeft: 3,
                fontFamily: 'Source Sans Pro',
                fontSize: 'calc(15px + 0.5vh)',
                fontWeight: 'bold',
            }}
            >
            PCP - Seller Data
            </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
            <Button
            variant="contained"
            color="primary"
            onClick={handleRefreshData}
            >
            Refresh Data
            </Button>
        </Grid>
        </Grid>
        <Typography
        sx={{
            marginTop: 0,
            marginLeft: 3,
            fontFamily: 'Source Sans Pro',
            fontSize: 'calc(7px + 0.5vh)',
            fontWeight: 'lighter',
        }}
        >
        </Typography>
        {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
                </div>
            </div>
        ) : (
        <DataGrid
        
        sx={{
            marginTop: '2vh',
            marginLeft: 1,
            marginRight: 2,
            padding: '10px 18px 18px 18px',
            backgroundColor: "#FFFFFF",
            border: '1px solid #e0e0e0',
            borderRadius: '10px',
            minHeight: '40vh',
            maxHeight: '90vh',
            overflow: 'auto', // Enable vertical scrolling if needed
        }}
        rows={data}
        columns={tableColumns}
        components={{
            Toolbar: CustomToolbar,
        }}
        slotProps={{
            toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            },
        }}
        editMode="row"
        disableColumnSelector
        disableDensitySelector
        initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
        }}  
        />
        )} 
    </div>
    );
    
    
};


export default withAuthenticator(SellerDataPCP);