import { Avatar } from '@mui/material';


// ------------------------------------------TABELA PARA ADMINS
export  const tableColumnsAdmin = [
    {
        field: "id",
        headerName: "MCID",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "seller_name",
        headerName: "Seller Name",
        width: '250',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "owner",
        headerName: "Owner",
        headerClassName: 'customHeader',
        width: '150',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            );
          }
      
          return null; 
        },
    },
    {
        field: "backup_owner",
        headerName: "Backup Owner",
        headerClassName: 'customHeader',
        width: '150',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            );
          }
      
          return null; 
        },
    },
    {
        field: "launch_date",
        headerName: "Launch Date",
        width: '100',
        editable: true,
        valueFormatter: params => new Date(params?.value).toLocaleDateString(),
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "old_classification",
        headerName: "Old Classification",
        width: '150',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },            
    {
        field: "new_classification",
        headerName: "New Classification",
        width: '150',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },     
    {
        field: "sales_cohort",
        headerName: "Sales Cohort",
        width: '250',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
        field: "nam",
        headerName: "Nam",
        width: '250',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "ship_method",
        headerName: "Ship Method",
        width: '200',
        editable: true,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    }, 
    ];

    // ------------------------------------------TABELA PARA OPS
export  const tableColumnsOps = [
  {
      field: "id",
      headerName: "MCID",
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "seller_name",
      headerName: "Seller Name",
      width: '250',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "owner",
      headerName: "Owner",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
          );
        }
    
        return null; 
      },
  },
  {
      field: "backup_owner",
      headerName: "Backup Owner",
      headerClassName: 'customHeader',
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const opsOwner = params?.value;
    
        if (opsOwner) {
          return (
            <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
          );
        }
    
        return null; 
      },
  },
  {
      field: "launch_date",
      headerName: "Launch Date",
      width: '100',
      editable: false,
      valueFormatter: params => new Date(params?.value).toLocaleDateString(),
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "old_classification",
      headerName: "Old Classification",
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },            
  {
      field: "new_classification",
      headerName: "New Classification",
      width: '150',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },    
  {
      field: "sales_cohort",
      headerName: "Sales Cohort",
      width: '250',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },  
  {
      field: "nam",
      headerName: "Nam",
      width: '250',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  },
  {
      field: "ship_method",
      headerName: "Ship Method",
      width: '200',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'customHeader',
  }, 
  ];

// ------------------------------------------TABELA PARA OPS
export  const tableColumnsView = [
    {
        field: "id",
        headerName: "MCID",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "seller_name",
        headerName: "Seller Name",
        width: '250',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "owner",
        headerName: "Owner",
        headerClassName: 'customHeader',
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            );
          }
      
          return null; 
        },
    },
    {
        field: "backup_owner",
        headerName: "Backup Owner",
        headerClassName: 'customHeader',
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          const opsOwner = params?.value;
      
          if (opsOwner) {
            return (
              <Avatar alt={opsOwner} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${opsOwner}`} />
            );
          }
      
          return null; 
        },
    },
    {
        field: "launch_date",
        headerName: "Launch Date",
        width: '100',
        editable: false,
        valueFormatter: params => new Date(params?.value).toLocaleDateString(),
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "old_classification",
        headerName: "Old Classification",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },            
    {
        field: "new_classification",
        headerName: "New Classification",
        width: '150',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },    
    {
        field: "sales_cohort",
        headerName: "Sales Cohort",
        width: '250',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },  
    {
        field: "nam",
        headerName: "Nam",
        width: '250',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    },
    {
        field: "ship_method",
        headerName: "Ship Method",
        width: '200',
        editable: false,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'customHeader',
    }, 
    ];