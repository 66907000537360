import { useState, useEffect, useCallback, useMemo } from "react";
import { listSummaryPCPS } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Button, Snackbar, Alert, Chip, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import awsconfig from '../../../aws-exports';
import { groupBy, sumBy } from 'lodash';
import './summary.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@aws-amplify/ui-react';
import { getISOWeek } from 'date-fns';


Amplify.configure(awsconfig);

const Summary = ({ loading, onRefresh, data, fetchData }) => {
  const [isLoading, setLoading] = useState(loading); 
  const [aggregatedData, setAggregatedData] = useState([]);
  const [groupByType, setGroupByType] = useState('week');
  const groupTypes = ['day', 'week', 'month'];

  useEffect(() => {
    // Função para agregar os dados com base no tipo de agrupamento selecionado
    const aggregateData = () => {
      const sortedData = [...data].sort((a, b) => new Date(b.ship_day) - new Date(a.ship_day));
      const groupedData = groupBy(sortedData, (item) => {
        const date = new Date(item.ship_day);
        switch (groupByType) {
          case 'day':
            return date.toISOString().split('T')[0]; // Agrupa por dia
          case 'week':
            const year = date.getFullYear();
            const week = getISOWeek(date);
            return `${year}-W${String(week).padStart(2, '0')}`; // Agrupa por semana
          case 'month':
            return `${date.getFullYear()}-M${String(date.getMonth() + 1).padStart(2, '0')}`; // Agrupa por mês
          default:
            return ''; // Tipo de agrupamento inválido
        }
      });
    
      // Mapeia os dados agrupados para o formato desejado
      const aggregated = Object.keys(groupedData).map((key) => {
        return {
          ship_day: key,
          shipped_units: sumBy(groupedData[key], 'shipped_units'),
          shipped_orders: sumBy(groupedData[key], 'shipped_orders'),
        };
      });
    
      setAggregatedData(aggregated);
    };
    
    aggregateData();
  }, [data, groupByType]);

  const handleGroupByChange = (type) => {
    setGroupByType(type);
  };


  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );

  useEffect(() => {
    console.log("Dados recebidos:", data);
  }, [data]);
  useEffect(() => {
    console.log("Dados agregados:", aggregatedData);
  }, [aggregatedData]);
  useEffect(() => {
    console.log("Estado de loading:", loading);
  }, [loading]);

  const mappedData = aggregatedData.map(item => ({
    id: item.ship_day, // Você pode definir um ID único para cada linha
    ship_day: item.ship_day,
    shipped_units: item.shipped_units,
    shipped_orders: item.shipped_orders,
  }));
  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Typography
            sx={{
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            Group By:
          </Typography>
          {groupTypes.map(type => (
            <Chip
              key={type}
              label={type}
              variant={groupByType === type ? 'filled' : 'outlined'}
              color={groupByType === type ? 'primary' : 'default'}
              onClick={() => handleGroupByChange(type)}
            />
            
          ))}
                  <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={onRefresh}
            sx={{ marginTop: '10px' }}
          >
            Refresh Data
          </Button>
        </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item lg={3} xs={3} md={3}>
        <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Vendo Flex - Summary
          </Typography>
        </Grid>
      </Grid>
      <Typography
        sx={{
          marginTop: 0,
          marginLeft: 3,
          fontFamily: 'Source Sans Pro',
          fontSize: 'calc(7px + 0.5vh)',
          fontWeight: 'lighter',
        }}
      >
      </Typography>
      {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
      ) : (
      <DataGrid
      
        sx={{
          marginTop: '2vh',
          marginLeft: 1,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '1px solid #e0e0e0',
          borderRadius: '10px',
          minHeight: '40vh',
          maxHeight: '90vh',
          overflow: 'auto', // Enable vertical scrolling if needed
        }}
        rows={mappedData}
        columns={[
          { field: 'ship_day', headerName: 'Ship Day', flex: 1, },
          { field: 'shipped_units', headerName: 'Shipped Units', flex: 1, },
          { field: 'shipped_orders', headerName: 'Shipped Orders', flex: 1, },
        ]}
        components={{
          Toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        editMode="row"
        disableColumnSelector
        disableDensitySelector
        initialState={{
            pagination: { paginationModel: { pageSize: 50 } },
        }}  
      />
      )} 
    </div>
    );
};

export default (Summary);