import { useState, useEffect, useCallback, useMemo } from "react";
import { listMQBandQBRS } from "../../../graphql/queries";
import { listMisses } from "../../../graphql/queries";
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarContainer, GridToolbarExport, GridCellEditStopReasons, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Typography, Grid, Button } from '@mui/material';

import awsconfig from '../../../aws-exports';

import tableColumns2 from './blank_reason_columns';

import './summary.css';
import { Loader } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const BlankReasonsSF = ({ username, userGroup, data, loading, fetchData, onRefresh }) => {

  function getTwoWeeksAgo() {
    const now = new Date();
    const oneWeekAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 15);
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
    const millisecondsTillOneWeekAgo = oneWeekAgo - startOfYear;
    const weekNumberOneWeekAgo = Math.ceil(millisecondsTillOneWeekAgo / millisecondsPerWeek);
    return weekNumberOneWeekAgo;
  }
  const twoWeeksAgo = getTwoWeeksAgo();

  function getCurrentWeek() {
    const now = new Date();
  
    if (now.getDay() === 0 || now.getDay() === 1) { 
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumberToday = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      const previousWeekNumber = weekNumberToday - 1;
      
      return previousWeekNumber;
    } else {
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
      const millisecondsTillToday = now - startOfYear;
      const weekNumber = Math.ceil(millisecondsTillToday / millisecondsPerWeek);
      
      return weekNumber;
    }
  }
  const currentWeekNumber = getCurrentWeek();

  const sortedData = useMemo(() => {
    const uniqueWeeks = Array.from(new Set(data.map(item => item.week)));
    const table = [];
    uniqueWeeks.forEach((week) => {
      const uniqueOpsOwners = Array.from(new Set(data.filter(item => item.week === week).map(item => item.ops_owner)));
      uniqueOpsOwners.forEach((opsOwner, index) => {
        const row = {
          id: `${week}_${opsOwner}_${index}`,
          week: week,
          ops_owner: opsOwner,
        };
        const missTypes = ['MXSD', 'CANCELLATION', 'LH_BY_SELLER'];
        missTypes.forEach(missType => {
          const units = data
            .filter(item => item.week === week && item.ops_owner === opsOwner && item.miss_type === missType && !item.reason)
            .reduce((sum, item) => sum + item.units, 0);
          const unitsNotBlank = data
            .filter(item => item.week === week && item.ops_owner === opsOwner && item.miss_type === missType && item.reason)
            .reduce((sum, item) => sum + item.units, 0);  
          const totalUnits = data
            .filter(item => item.week === week && item.ops_owner === opsOwner && item.miss_type === missType)
            .reduce((sum, item) => sum + item.units, 0);
          const percent = totalUnits > 0 ? ((unitsNotBlank / totalUnits) * 100).toFixed(2) : 0;
          row[`${missType}_units`] = units;
          row[`${missType}_percent`] = percent;
          row[`${missType}_ordersNotBlank`] = unitsNotBlank;
          row[`${missType}_total`] = totalUnits;
        });
        table.push(row);
      });
    });
  
    const sortedItems = table.sort((a, b) => {
      const weekComparison = b.week - a.week;
      if (weekComparison !== 0) {
        return weekComparison;
      }
      // Calculate the sum of percentages for each item
      const sumA = a.CANCELLATION_units + a.MXSD_units + a.LH_BY_SELLER_units;
      const sumB = b.CANCELLATION_units + b.MXSD_units + b.LH_BY_SELLER_units;
      // Sort by the sum of percentages in descending order
      return sumB - sumA;
    });
  
    return sortedItems;
  }, [data]);

  useEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error fetching user group information:', error);
    }
  }, []);

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        marginBottom: '10px',
      }}
      spacing={2}
    >
    </GridToolbarContainer>
  );
  return (
    <div className='datatable' style={{ height: '100%', width: '100%' }}>
      <Grid item xs={12} textAlign="center" style={{ marginTop: '2vh' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onRefresh}
        >
          Refresh Data
        </Button>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item xs={3} md={3}>
          <Typography
            sx={{
              marginTop: '2vh',
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(15px + 0.5vh)',
              fontWeight: 'bold',
            }}
          >
            Blank Reasons
          </Typography>
          <Typography
            sx={{
              marginTop: 0,
              marginLeft: 3,
              fontFamily: 'Source Sans Pro',
              fontSize: 'calc(7px + 0.5vh)',
              fontWeight: 'lighter',
            }}
          >
            
            
          </Typography>
        </Grid>
      </Grid>
      {loading ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginTop: '20vh' }}>
                <Loader size="large" /> 
              </div>
            </div>
            ) : (
      <DataGrid
        sx={{
          marginTop: '2vh',
          marginLeft: 1,
          marginRight: 2,
          padding: '10px 18px 18px 18px',
          backgroundColor: "#FFFFFF",
          border: '0px',
          borderRadius: '10px',
          minHeight: '40%',
          maxHeight: '40%',
        }}
        rows={sortedData}
        columns={tableColumns2}
        components={{
          Toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        editMode="row"
        disableColumnSelector
        disableDensitySelector 
        autoHeight 
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          filter: {
            filterModel: {
              items: [{ field: 'week', operator: '>=', value: currentWeekNumber }],
            },
          },
      }}  
      />
      )}  
    </div>
    );
};

export default (BlankReasonsSF);